import { Cartographic } from "@itwin/core-common";
import { BeButton, BeButtonEvent, DecorateContext, Decorator, EventHandled, HitDetail, IModelApp, IModelConnection, Marker, MarkerFillStyle, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { Point2d, Point3d } from "@itwin/core-geometry";
// import App from "../../components/App";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { WidgetState } from "@itwin/appui-react";
import { DefectData, DefectsTable } from "../../components/Tables/DefectsTable";

import "./PinTagElements.scss";
import { Id64String } from "@itwin/core-bentley";
import axios from "axios";
import { Logger } from "../../api/logging";
import { ConfigManager } from "../../../config/ConfigManager";
import { DTVActions } from "../../../store/Actions";
import { store } from "../../../store/rootReducer";
import { getAccessToken } from "../../../services/auth/AuthContext";
import { PropertyTableType } from "../../../store/States";
import { addToBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import DefectsClient from "../../api/defects";
import PinTagClient from "../../api/pinTagClient";
import { DigitalTwinViewerApp } from "../../../api/DigitalTwinViewerApp";
import { SyncUiEventIds, licenseProductIds } from "../../../store/redux-types";
import { deductLicense } from "../../components/LicenseWorkflow";
import { DefectsDecorator } from "./DefectsDecorator";

//As this decorator is doing more than show defect markers,
//this needs to be renamed to Pin Annotation Decorator 
//and change naming convention for all variables (pending)
export class AnnotationDecorator implements Decorator {

    //-----------------------------------------------------
    private static _defectData: any;                              // Is named defect data , but holds the data for both defects and Pin Annotations based on use and context.    
    public useCachedDecorations: true | undefined = true;           
    public static _markers: Marker[] = [];                        // Holds all the current markers created and instanciated
    public static selectedMarkerJson: any | undefined;            // The marker object that has been click on and selected.
    public publicData: any[] = [];
    public objectIdMap: Map<Id64String, string> = new Map<string, Id64String>();
    public static lastCreatedPinDefect: Marker | undefined;       // holds the refrence to the pin that was newly created.
    public static createdDefectStore: Marker[] = [];              // this stores all the pins created by user. This is used to clear previsous stores from view when saving is done.
    private static currentHoverElementInfo: any | undefined;      // Holds the data for the element and mousueposition of the lement the mouse is over.
   //-----------------------------------------------------
    constructor() {
       //this adds a listner that will, on mouse move will return the current element its is hovering over.
       //This is needed for the On MarkerHover div popup feature.
        document.addEventListener('mousemove', function (e) {
        if (e != null && e.target != null) {//test
            AnnotationDecorator.currentHoverElementInfo = { element: e.target as HTMLInputElement, posX: e.clientX, posY: e.clientY }
            let ele = document.getElementsByClassName(AnnotationDecorator.currentHoverElementInfo.element.parentElement?.className) as HTMLSelectElement;
            AnnotationDecorator.currentHoverElementInfo = { element: e.target as HTMLInputElement, parent: ele, posX: e.clientX, posY: e.clientY }
        }
     },false);
   }
  //-----------------------------------------------------
  public decorate(context: DecorateContext): void
  {
          //This will capture the current Element that the mouse is hovering over as well as the current positon of the mouse.
    if (AnnotationDecorator._markers.length === 0) {
        this.loadPinDefectMarkers(/*context,*/ AnnotationDecorator._defectData);
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
  
    AnnotationDecorator._markers?.forEach((marker) => {
      marker.addDecoration(context);
    });
  }
  //-----------------------------------------------------
    /*
     * Delete and deallocate all entites   
    */
    public terminate() {
        this.clearClassElements("pin-tag-popup");//clear the popu div as well.
        AnnotationDecorator._markers.length = 0;
        AnnotationDecorator._defectData = undefined;
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }

    //-----------------------------------------------------
    /*
     * InvalidateCache
    */
    public refreshInvalidateCache() {
        IModelApp.viewManager.invalidateCachedDecorationsAllViews(this);
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }
    //-----------------------------------------------------
    /*
     * Remove Object from the array of markers by marker object.
     * Takes a Marker object as param and comapres position to 
     * remove relevant marker.
     */ 
    public removeDefectMarkerObject(object: Marker) {
        if (object != undefined) {
            if (AnnotationDecorator._markers.length > 0) {
                for (let i = 0; i < AnnotationDecorator._markers.length; i++) {
                    if (AnnotationDecorator._markers[i].position == object.position) {
                        AnnotationDecorator._markers.splice(i, 1);
                    }
                }
            }
        }
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    //----------------------------------------------------
    /*
     * To make them appear unselected
     */
    public static resetMarkersImage() {
        if (AnnotationDecorator._markers != undefined && AnnotationDecorator._markers.length > 0) {
            AnnotationDecorator._markers.forEach((e) => {
                const im = e.image as HTMLImageElement;
                if (im.src.includes("defect-camera-icon-select")) {
                    e.setImageUrl("image/defect-camera-icon2.png");
                } else if (im.src.includes("image/location-coloured")) {
                    e.setImageUrl("image/location.SVG");
                }
            });
        }
    }
    //-----------------------------------------------------
    /*
     * Creates a marker on Point, .
     */ 
    public async addMarkerOnPoint(point: Point3d) {
        const marker = new Marker(point, new Point2d(22, 22));
      marker.position = point;
      marker.setImageUrl("image/location.SVG");

        // As per the structure for markers 
        // this allows for mouse event to triger on interaction with the same marker refrence
        marker.onMouseLeave = () => {
        };
        marker.onMouseEnter = (_ev: BeButtonEvent) => {
        };
        marker.onMouseButton = (_ev: BeButtonEvent) => {
            return true;
        };

        AnnotationDecorator.lastCreatedPinDefect = marker;
        AnnotationDecorator.createdDefectStore.push(marker);
        AnnotationDecorator._markers.push(marker);
        const vp = IModelApp.viewManager.selectedView;
        if (vp === undefined) return;
        const nextId = vp.iModel.transientIds.getNext();
        // this.objectIdMap.set(`defectMarker#${AnnotationDecorator._markers.length-1}`, nextId);
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }

    //----------------------------------------------------------------------------------------
    /*
     * All elements under the class name psc will be cleared
     * This helps refresh the page with new elements witouth appending
     * the page with duplicates.
     */
    //-------------------------------------------------------------------------------------
    /*
     * Remove Html element base on Id 
     */
    private async clearElement (id : any) {
        var elem = document.getElementById(id);
        elem?.parentNode?.removeChild(elem);
    }
    //-------------------------------------------------------------------------------------
    /*
     * Remove Html element base on Class name 
     * matches the id to delete , so make sure the 
     * element has an id assigned to it.
     */
    private async clearClassElements (className: string) {
        let elements = document.getElementsByClassName(className);
        let idArray: string[] = [];
        if (elements) {
            for (let i = 0; i < elements.length; i++) {
                idArray.push(elements[i].id.toString());
            }
            for (let j = 0; j < idArray.length; j++) {
                this.clearElement(idArray[j]);
            }
        }
    }
  //-----------------------------------------------------  
  /*
  * Takes the Data from the API as parameter and loads the Defect Markers 
  * when Display Detected Defects button is pressed.
  */ 
  public async loadPinDefectMarkers(data : any[]): Promise<void> {
          AnnotationDecorator._defectData = data;
      let json;
      if (data ) {
         json = data;
      }
    if (!json) {
      return;
    }
    const mlPoints = json;
    let type = ""
    if (/*context &&*/ mlPoints) {//Iterate over the Pin Defects data.
      for (let i = 0; i < mlPoints.length; i++) {
          let cart: Cartographic, iModel: IModelConnection, utmXY: Point3d, heightBuffer: number, spatialPoint: Point3d, ecefX: number, ecefY: number, ecefZ: number, oldCentroidPtInGlobal: Point3d;
          if (mlPoints[i].className === "AI_ML_Defect") {
            cart = Cartographic.fromDegrees( {longitude: mlPoints[i].longitude, latitude: mlPoints[i].latitude, height: mlPoints[i].altitude});
            // let utmXY = this.LatLong2UTM(mlPoints[i].latitude, mlPoints[i].longitude, 10);
            iModel = UiFramework.getIModelConnection()!;
            utmXY = await iModel.cartographicToSpatial(cart);
            heightBuffer = iModel.spatialToCartographicFromEcef(new Point3d(utmXY.x, utmXY.y, mlPoints[i].altitude)).height;
            spatialPoint = new Point3d(utmXY.x, utmXY.y, (mlPoints[i].altitude - heightBuffer) + mlPoints[i].altitude);
          } else {
            mlPoints[i].pinInformation[0].toString().split('.')[0].length
           let pins = mlPoints[i].pinInformation.replace(/\[|\]/g,'').split(',');
            ecefX = Number(parseFloat(pins[0]));
            ecefY = Number(parseFloat(pins[1]));
            ecefZ = Number(parseFloat(pins[2]));
                
                spatialPoint = new Point3d(ecefX, ecefY, ecefZ);
          }

        const marker = new Marker(spatialPoint, new Point2d(22, 22));
                marker.visible = true;

        if (mlPoints[i].userData === undefined) {
            mlPoints[i].userData = { marker, className: mlPoints[i].className };
        }

        if (mlPoints[i].className === "AI_ML_Defect") {
            marker.setImageUrl("image/defect-camera-icon.png");
            type = "detected"
        }
        else {
            marker.setImageUrl("image/location.SVG");
            type = "manual"
        }

           //-----When marker hover mouse leaves 
        marker.onMouseLeave = () => {
            marker.label = "";
            marker.imageSize = {x: 38, y: 38};
        };
          //-----When marked is hovered over
        marker.onMouseEnter = (_ev: BeButtonEvent) => {
            marker.label = mlPoints[i].criticality + ": " + mlPoints[i].defect;
            marker.labelBaseline = "bottom";
            marker.labelOffset = {x: 0, y: 20};
            marker.imageSize = {x: 48, y: 48};
            return true;
        };
           //-----When marker is cliced
        marker.onMouseButton = (_ev: BeButtonEvent) => {
            let type = "";
            if (_ev.button === BeButton.Data) {
                AnnotationDecorator._markers.forEach((e) => {
                    const im = e.image as HTMLImageElement;
                    if (im.src.includes("defect-camera-icon-select")) {
                        e.setImageUrl("image/defect-camera-icon2.png");
                        type = "detected"
                    } else if (im.src.includes("image/location-coloured")) {
                        e.setImageUrl("image/location.SVG");
                        type = "manual"
                    }
                });

                const im = marker.image as HTMLImageElement;
                if (im.src.includes("defect-camera-icon")) {
                    marker.setImageUrl("image/defect-camera-icon-select.png");
                    type = "detected"
                }
                else {
                    marker.setImageUrl("image/location-coloured.SVG");
                    type = "manual"
                }

                AnnotationDecorator.selectedMarkerJson = mlPoints[i];
                const iModel = UiFramework.getIModelConnection()!;
                iModel!.selectionSet.emptyAll();
                const selName = `defectMarker#${mlPoints[i].defectId}#${type}`;
                let getId: Id64String = "";
                this.objectIdMap.forEach((e, i)=>{if(e==selName){getId=i;return;}});
                if(getId.length)iModel!.selectionSet.add(getId!);
        
                // show defect widget
                if (im.src.includes("defect-camera-icon")) {
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                    // findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                    // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);
                    // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);
                } else {
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                    // findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                    // SyncUiEventDispatcher.dispatchSyncUiEvent("pin-selected");
                }
            }
            else if (_ev.button === BeButton.Reset) {
                const im = marker.image as HTMLImageElement;
                AnnotationDecorator.selectedMarkerJson = undefined;

                if (im.src.includes("defect-camera-icon")) {
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                    // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);
                }
                else {
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                    // SyncUiEventDispatcher.dispatchSyncUiEvent("pin-unselected");
                }
            }
            else {
               return false;
            }
            return true;
        };
        
          AnnotationDecorator._markers.push(marker);
          this.publicData.push(mlPoints[i]);
          const vp = IModelApp.viewManager.selectedView;
          if (vp === undefined) return;
          const nextId = vp.iModel.transientIds.getNext();
          this.objectIdMap.set(nextId, `defectMarker#${mlPoints[i].defectId}#${type}`);
        }
    }
  }
  

  /** Return true if supplied Id represents a pickable decoration created by this decorator. */
  public testDecorationHit(_id: string): boolean {
    return Array.from(this.objectIdMap.values()).includes(_id);
  }
  private static getEnvironmentPrefix = () => {
    let prefix = ""
    if(ConfigManager.regionCode == 103)prefix="dev-"
    else if(ConfigManager.regionCode == 102)prefix="qa-"
    return prefix
}

  private async projectShareIdToFile(wsgId : string) {
    let token : any = store.getState().auth.accessTokenState.accessToken;//Project assess token
    // let requestContext = new AuthorizedClientRequestContext(t);
    // let _projectShareClient: ProjectShareClient = new ProjectShareClient();
    //This is the proper way of getting the URL.
    //THe url should be freshly generated everytime the user wants to access the file.
    //As the URL expires saving the URL directly is issue prone.
    // const queryIfi = new ProjectShareFileQuery().byWsgIds(wsgId);

    let baseUrl = new URL(`https://${AnnotationDecorator.getEnvironmentPrefix()}api.bentley.com/storage/files/${wsgId}`);

    const file: any =  await axios.get(baseUrl.href,{
        headers:{
            Authorization:token,
            Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                 } 

    });
    // const queryIfi = new ProjectShareFileQuery().byWsgIds(wsgId);
    // const file: any = await _projectShareClient.getFiles(ConfigManager.projectId, queryIfi);
    if (file) {
        return file;
    }
}

public async getPinsAndDefect (showMessage = true) {
    for (const dec of IModelApp.viewManager.decorators) {
        if (dec.constructor.name.includes("AnnotationDecorator")) {
            (dec as AnnotationDecorator).terminate();
            IModelApp.viewManager.dropDecorator(dec);
        }
    }
    const tokenString = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
    const iModel = UiFramework.getIModelConnection();
    let allDetectedDefects: DefectData[] = [];
    let allAnnotationDecorator: any = IModelApp.viewManager.decorators.filter(e=>e.constructor.name=="AnnotationDecorator");
    let allDefectsDecorator: any = IModelApp.viewManager.decorators.filter(e=>e.constructor.name=="DefectsDecorator");
    let showDefects = true;
    if(allDefectsDecorator.length)showDefects = false;
    let defectsdec = new AnnotationDecorator;
    IModelApp.viewManager.addDecorator(defectsdec)!;

  let data = await PinTagClient.getPinTags(tokenString);
  
                if (data != undefined) {
    
        // this.setState({ enabledForTagEditDelete: false });//enable/disable the delete button.
        
         AnnotationDecorator.selectedMarkerJson = { fullList: true, data: data }

        // clearOldMarkers();
        AnnotationDecorator.lastCreatedPinDefect = undefined;//make this undefined so that the Pinslist.tsx condition 'if(AnnotationDecorator.lastCreatedPinDefect != undefined)' does not trigger.
        // AddPinAnnotationTool.refreshAllMarkers();//Refresh all markers including the data after save is pressed, so that the user can continue working with updated data
        const pinWidget :any= UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PinListWidget");

        store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_PINS_ANNOTATION_LIST))
        if (defectsdec) {
            if(showDefects && ConfigManager.INsiteImageUrl.length){
                const baseAlt = await DefectsClient.getBaseAltitude(tokenString);

                const pinDefects = await DefectsTable.getPinsData(iModel!);
                let defects = pinDefects.filter(pins=>pins.typeOfAIAI == "PIN" && pins.latitude != "Inactive")
                allDetectedDefects = allDetectedDefects?.concat(defects);
                pinDefects.map((e) => e.info = {baseAlt});
            }
        
          //     SampleToolWidget.allDetectedDefects = SampleToolWidget.allDetectedDefects?.concat(pinDefects);
        
              //if decorator exist use the existing to append decorator entites
               let hasDecorator: boolean = false;
               for (const dec of IModelApp.viewManager.decorators) {
                  if (dec.constructor.name.includes("AnnotationDecorator")) {
                       hasDecorator = true;
                       (dec as AnnotationDecorator).terminate();
                       (dec as AnnotationDecorator).loadPinTagMarkers(data);
                       IModelApp.viewManager.selectedView?.invalidateDecorations();
                       IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
                       (dec as AnnotationDecorator).loadPinDefectMarkers(allDetectedDefects!);
                       allAnnotationDecorator = [dec];
                       setTimeout(() => {store.dispatch(addToBuilt3DObjectIdsMap(new Map((dec as AnnotationDecorator).objectIdMap)));}, 0);
                  }
               }
              if (!hasDecorator) {
                  const allDefs = new AnnotationDecorator();
                   allDefs.loadPinTagMarkers(data)
                  allDefs.loadPinDefectMarkers(allDetectedDefects!)
                  allAnnotationDecorator = IModelApp.viewManager.addDecorator(allDefs)!;
                  IModelApp.viewManager.selectedView?.invalidateCachedDecorations(allDefs);
                  setTimeout(() => {store.dispatch(addToBuilt3DObjectIdsMap(new Map(allDefs.objectIdMap)));}, 0);
              }
              if(DigitalTwinViewerApp.checkForLicense)deductLicense(licenseProductIds.InspectionTools);

          }
        if(pinWidget){
          
          if(pinWidget !== undefined && pinWidget.state!=0){
            pinWidget.setWidgetState(WidgetState.Open);
            }
            else{
      
                pinWidget.setWidgetState(WidgetState.Hidden);
            } 
        }
    
          }else if(data == null && showMessage)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning,"Data Does Not Exist", "", OutputMessageType.Toast));

        //   store.dispatch(setNewPinAnnotation(copyPinSelected));
        
    //SampleToolWidget.showPinTags = !SampleToolWidget.showPinTags;//flip the state
    return;

}
    private async downloadImage(wsgId : string) {
        try{

            let token :any = store.getState().auth.accessTokenState.accessToken;//Project assess token
            var myHeaders = new Headers();
            myHeaders.append("Authorization",token);
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: undefined
            };
            let img;
           let data = await fetch(`https://${AnnotationDecorator.getEnvironmentPrefix()}api.bentley.com/storage/files/${wsgId}/download`, requestOptions).then(res=>{
               img = res.url
            //    res.url
        })
           
           

            // let baseUrl = new URL(` https://qa-api.bentley.com/storage/files/${wsgId}/download`);
    
            // const file: any =  await axios.get(baseUrl.href,{
            //     headers:{
            //         Authorization:token,
            //         Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
            //              } 
    
            // });
           
            return img;
        }catch(e){
            Logger.error("Error while downloading image",e)
        }
    }
    //---------------------------------------------------------------------------------------------------------------
    /*
    * Pin Visualisation for Pin Tag Annotations. 
    * Uses the fetched data to visualise the relvant info
    * in scene as markers.
    */
  public async loadPinTagMarkers(data: any[]): Promise<void> {
        AnnotationDecorator._defectData = data;
    if (data != undefined) {
        const mlPoints = data;

        let tagTypeCounter: number = 0;
      if (mlPoints) {//Iterate over the Pin Tag Annotation data.
        for (let i = 0; i < mlPoints.length; i++) {

            let mpoint = new Point3d(mlPoints[i].position[0], mlPoints[i].position[1], mlPoints[i].position[2])
            const marker = new Marker(mpoint, new Point2d(28, 28));
            marker.visible = true;

            let isImageMarker: boolean = false;
            let isCombination: boolean = false;

            let imagecount: number = 0;
            if (mlPoints[i].documentLinks != null) {
                for (let m = 0; m < mlPoints[i].documentLinks.length; m++) {
                    if (mlPoints[i].documentLinks[m].title.match(/\.(jpg|jpeg|png|gif)$/i)) {//count the number of images
                        imagecount++;
                    }
                }

                let ratio = ((mlPoints[i].documentLinks.length + 1) / imagecount);//calc the ration fo images to regular files.
                if (ratio < 2) {
                    isImageMarker = true;
                }
                else {
                    isCombination = true;
                }
            }
            if (mlPoints[i].userData === undefined) {
                mlPoints[i].userData = { marker, className: mlPoints[i].className };
            }
            if (mlPoints[i].documentLinks == null && mlPoints[i].description != null && mlPoints[i].externalLinks == null) {
                marker.setImageUrl("image/PinTagMarkers/comment.png");//default
            }
            if (mlPoints[i].documentLinks != null ) {
                if (mlPoints[i].documentLinks != undefined && (mlPoints[i].externalLinks == null || mlPoints[i].externalLinks.length == 0) && mlPoints[i].documentLinks.length > 0 && isImageMarker == false) {
                    marker.setImageUrl("image/PinTagMarkers/docs.png");
                }
            }
            if (mlPoints[i].externalLinks != null) {
                if (mlPoints[i].externalLinks[0].link != undefined && (mlPoints[i].documentLinks == null || mlPoints[i].documentLinks.length == 0) && isImageMarker == false) {
                    marker.setImageUrl("image/PinTagMarkers/links.png");
                }
            }
            if ( mlPoints[i].documentLinks != null) {
                if (mlPoints[i].documentLinks != undefined && isImageMarker == true && isCombination == false) {
                    marker.setImageUrl("image/PinTagMarkers/images.png");
                }
            }
            if (mlPoints[i].externalLinks != null && mlPoints[i].documentLinks != null) {
                if (mlPoints[i].documentLinks != undefined && mlPoints[i].externalLinks != undefined && isCombination == true && isImageMarker == false) {
                    marker.setImageUrl("image/PinTagMarkers/fullbox.png");
                }
            }

            //Leave hover over marker---------------------------------
            marker.onMouseLeave = () => {
                marker.label = "";
                marker.imageSize = { x: 28, y: 28 };

                AnnotationDecorator._markers.forEach((e) => {
                    const im = e.image as HTMLImageElement;
                    if (im.src.includes("image/PinTagMarkers/comment-colored.png")) {
                        e.setImageUrl("image/PinTagMarkers/comment.png");
                    }
                    if (im.src.includes("image/PinTagMarkers/docs-colored.png")) {
                        e.setImageUrl("image/PinTagMarkers/docs.png");
                    }
                    if (im.src.includes("image/PinTagMarkers/links-colored.png")) {
                        e.setImageUrl("image/PinTagMarkers/links.png");
                    }
                    if (im.src.includes("image/PinTagMarkers/images-colored.png")) {
                        e.setImageUrl("image/PinTagMarkers/images.png");
                    }
                    if (im.src.includes("image/PinTagMarkers/fullbox-colored.png")) {
                        e.setImageUrl("image/PinTagMarkers/fullbox.png");
                    }
                });

                let img = marker.image as HTMLImageElement;
                if (img.src.includes("image/PinTagMarkers/comment.png")) {
                    marker.setImageUrl("image/PinTagMarkers/comment-colored.png");
                }
                if (img.src.includes("image/PinTagMarkers/images.png")) {
                    marker.setImageUrl("image/PinTagMarkers/images-colored.png");
                }
                if (img.src.includes("image/PinTagMarkers/links.png")) {
                    marker.setImageUrl("image/PinTagMarkers/links-colored.png");
                }
                if (img.src.includes("image/PinTagMarkers/docs.png")) {
                    marker.setImageUrl("image/PinTagMarkers/docs-colored.png");
                }
                if (img.src.includes("image/PinTagMarkers/fullbox.png")) {
                    marker.setImageUrl("image/PinTagMarkers/fullbox-colored.png");
                }
                if (img.src.includes("image/PinTagMarkers/location.png")) {
                    marker.setImageUrl("image/PinTagMarkers/location-colored.png");
                }

                this.refreshInvalidateCache();
                //this.clearClassElements("popup");//clear the popup element that comes up on hover on marker.
            };

            //------When hovering over marker--------------------------
            marker.onMouseEnter = async (_ev: BeButtonEvent) => {
                marker.label = "";//mlPoints[i].title;
                marker.labelColor = "black";
                marker.labelBaseline = "bottom";
                marker.labelOffset = { x: 0, y: 20 };
                marker.imageSize = { x: 35, y: 35 };

                //------------------------on Hover Popup---------------------------------
                //Appends a custom HTML content over the the div where the pin marker will be present.
                // in this case it will be the Itwin viewers dom. So create a popup div over it. the Z index will always be above. as in (**)
                if (AnnotationDecorator.currentHoverElementInfo.parent[0] != undefined && AnnotationDecorator.currentHoverElementInfo.parent[0] != null )
                {
                    this.clearClassElements("pin-tag-popup");
                        //Create a popup element div , to show custom data inside.
                        //when mouse hovers over the marker.
                        var div = document.createElement("div");
                        //div.innerHTML += '<span class="popuptext" id="myPopup" >' + mlPoints[i].title + '</span>';
                        div.className = "pin-tag-popup";
                        div.style.width = "100px";
                        div.id = "pin-tag-popup";
                        div.style.height = "100px";
                        div.style.position = "relative";//(**)
                        div.style.zIndex = (parseInt(AnnotationDecorator.currentHoverElementInfo.element.style.zIndex) + 1).toString();//(**)

                        var sp = document.createElement("span");
                        var linkText = document.createTextNode(mlPoints[i].title);
                        sp.appendChild(linkText);
                        sp.id = "myPopup";
                        sp.className = "popuptext";

                        var button = document.createElement("div");
                        //button.innerHTML += '<img src=' + f[0].accessUrl + 'width = "90" height = "90" alt = "submit" />'
                        button.className = "popup-cancel";
                        button.id = "popup-cancel"
                        button.onclick = function (this) {
                            clearClassElementsX("pin-tag-popup");//clear the popup element that comes up on hover on marker. Due to scoping constraints , the functuion is defined outside as a global static.
                        }

                        sp.appendChild(button);
                        div.appendChild(sp);
                    
                        // Position:
                        const absX = AnnotationDecorator.currentHoverElementInfo.posX;
                        const absY = AnnotationDecorator.currentHoverElementInfo.posY;

                        div.style.left = absX.toString() + "px";//position the div over the location of the cursor
                        div.style.top = absY.toString() + "px";

                    if (AnnotationDecorator.currentHoverElementInfo.parent.length >= 1) {
                        AnnotationDecorator.currentHoverElementInfo.parent[0].appendChild(div);
                    }
                }

                //THe popup HTML element.
                var popup = document.getElementById("myPopup");
                if (popup) {
                    popup.classList.toggle("show");//check the css file

                        //Create segment wrappers that hold documents , images and external link.
                        //------------------
                        let hasImages: boolean = false;//create wrapped only if data exists.\
                        let hasDocs: boolean = false;
                        if (mlPoints[i].documentLinks != null) {
                            for (let m = 0; m < mlPoints[i].documentLinks.length; m++) {
                                let f = await this.projectShareIdToFile(mlPoints[i].documentLinks[m].link);//generate a link to the file from project share.
                                if (f != undefined && f.status == 200) {
                                    if (f.data.file.path.includes(".JPG") || f.data.file.path.includes(".PNG") || f.data.file.path.includes(".JPEG") || f.data.file.path.includes(".jpeg") || f.data.file.path.includes(".png") || f.data.file.path.includes(".jpg")) {
                                        hasImages = true;
                                    }
                                    else {
                                        hasDocs = true;
                                    }
                                    if (hasImages && hasDocs) {
                                        break;
                                    }
                                }
                                else {
                                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning,"File Links Are Broken , Please Check Source Directory." , "", OutputMessageType.Toast));
                                }
                            }
                        }
                        var spswrp = document.createElement("div");//wrapper for project images
                        if (hasImages) {
                            spswrp.id
                            spswrp.className = "spswrp";
                            popup.appendChild(spswrp);
                        }

                        //------------------
                        var elinkwrp = document.createElement("div");//wrapper for external links
                        if (mlPoints[i].externalLinks != undefined && mlPoints[i].externalLinks != null && mlPoints[i].externalLinks[0] != undefined) {                     
                            elinkwrp.id = "elinkwrp";
                            elinkwrp.className = "elinkwrp";
                            popup.appendChild(elinkwrp);
                        }
                        //------------------
                        var dlinkwrp = document.createElement("div");//wrapper for project document links
                        if (hasDocs) {                  
                            dlinkwrp.id = "dlinkwrp"
                            dlinkwrp.className = "dlinkwrp";
                            popup.appendChild(dlinkwrp);
                        }

                        //------------------
                        //append the external link
                        if (mlPoints[i].externalLinks != undefined && mlPoints[i].externalLinks != null && mlPoints[i].externalLinks[0] != undefined) {
                            let l = document.createElement('a');
                            var linkText = document.createTextNode("eLink");
                            l.appendChild(linkText);
                            l.title = mlPoints[i].externalLinks[0].link;
                            l.className = "elink";
                            l.id = "elink-" + mlPoints[i].externalLinks[0].title;
                            l.onclick = function (this) {
                                window.open(mlPoints[i].externalLinks[0].link/*, '_blank', 'popUpWindow,height=400,width=600,left=10,top=10,scrollbars=yes,menubar=no'*/);
                            }

                            elinkwrp.appendChild(l);
                        }
                        //append images and Project Share links
                    if (mlPoints[i].documentLinks != null) {
                        for (let m = 0; m < mlPoints[i].documentLinks.length; m++) {
                            let f = await this.projectShareIdToFile(mlPoints[i].documentLinks[m].link);//generate a link to the file from project share.
                            if (f != undefined && f.status == 200 ) {
                                let img :any= await this.downloadImage(f.data.file.id)
                                if (f) {

                                    var sps = document.createElement("div");
                                    sps.title = f.data.file.displayName;
                                    sps.id = "subspan-img" + m;
                                    sps.className = "subspan-img";

                                    //convert the projectshare link string to consumable URL format.
                                    let urr:any = img;
                                    // urr = urr.replace(/"/g, "%22");//escape quotes to supported alternative.
                                    let link: string = 'url("' + urr + '")';
                                    sps.style.backgroundImage = link;

                                    sps.onclick = function (this) {
                                        // window.open(f[0].accessUrl/*, '_blank', 'popUpWindow,height=400,width=600,left=10,top=10,,scrollbars=yes,menubar=no'*/);
                                    }
                                    spswrp.appendChild(sps);
                                }
                                else {
                                    let l = document.createElement('a');
                                    var linkText = document.createTextNode(f[0].name);
                                    l.appendChild(linkText);
                                    l.title = f[0].name;//f[0].accessUrl;
                                    l.className = "plink";
                                    l.id = "plink-" + f[0].name;
                                    l.onclick = function (this) {
                                        // window.open(f[0]?.accessUrl/*, '_blank', 'popUpWindow,height=400,width=600,left=10,top=10,scrollbars=yes,menubar=no'*/);
                                    }

                                    dlinkwrp.appendChild(l);
                                    var br = document.createElement("br");//Cosmetic new line workaround
                                    br.className = "plink-br";
                                    br.id = "plink-br_";
                                    dlinkwrp.appendChild(br);
                                }
                            }
                        }
                    }
                }
                //--------------------
                return true;
            };
            //-----On Marker Click--------------------------
            marker.onMouseButton = (_ev: BeButtonEvent) => {

                if (_ev.button === BeButton.Data) {
                    AnnotationDecorator._markers.forEach((e) => {
                        const im = e.image as HTMLImageElement;
                        if (im.src.includes("image/PinTagMarkers/comment-colored.png")) {
                            e.setImageUrl("image/PinTagMarkers/comment.png");
                        }
                        if (im.src.includes("image/PinTagMarkers/docs-colored.png")) {
                            e.setImageUrl("image/PinTagMarkers/docs.png");
                        }
                        if (im.src.includes("image/PinTagMarkers/links-colored.png")) {
                            e.setImageUrl("image/PinTagMarkers/links.png");
                        }
                        if (im.src.includes("image/PinTagMarkers/images-colored.png")) {
                            e.setImageUrl("image/PinTagMarkers/images.png");
                        }
                        if (im.src.includes("image/PinTagMarkers/fullbox-colored.png")) {
                            e.setImageUrl("image/PinTagMarkers/fullbox.png");
                        }
                    });

                    let img = marker.image as HTMLImageElement;
                    if (img.src.includes("image/PinTagMarkers/comment.png")) {
                        marker.setImageUrl("image/PinTagMarkers/comment-colored.png");
                    }
                    if (img.src.includes("image/PinTagMarkers/images.png")) {
                        marker.setImageUrl("image/PinTagMarkers/images-colored.png");
                    }
                    if (img.src.includes("image/PinTagMarkers/links.png")) {
                        marker.setImageUrl("image/PinTagMarkers/links-colored.png");
                    }
                    if (img.src.includes("image/PinTagMarkers/docs.png")) {
                        marker.setImageUrl("image/PinTagMarkers/docs-colored.png");
                    }
                    if (img.src.includes("image/PinTagMarkers/fullbox.png")) {
                        marker.setImageUrl("image/PinTagMarkers/fullbox-colored.png");
                    }
                    if (img.src.includes("image/PinTagMarkers/location.png")) {
                        marker.setImageUrl("image/PinTagMarkers/location-colored.png");
                    }

                    this.refreshInvalidateCache();

                    AnnotationDecorator.selectedMarkerJson = mlPoints[i];

                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                    // SyncUiEventDispatcher.dispatchSyncUiEvent("pin-selected");
                    // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Defect_Annotation_Selected);
                    if(store.getState().dtvState.featureControls.restrictCrudOperations && !store.getState().dtvState.applicationState.isLoggedInUserAdmin ){
                        store.dispatch(DTVActions.setShowPinTags(false));
                    store.dispatch(DTVActions. setEditModeFlag(false) )
                    }else{

                        store.dispatch(DTVActions.setShowPinTags(true));
                        store.dispatch(DTVActions. setEditModeFlag(true) )
                    }
                    // App.ShowDeleteMount = false;//this is a necessart step to avoid conflict between 'Delete Marked Equipment' & 'Delete Mount' in Sampletoolwidget.
                }
                else if (_ev.button === BeButton.Reset)
                {
                    const im = marker.image as HTMLImageElement;
                    AnnotationDecorator.selectedMarkerJson = undefined;

                    if (im.src.includes("defect-camera-icon")) {
                        // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                        SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Nothing_Selected);
                    }
                    else {
                        // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                        SyncUiEventDispatcher.dispatchSyncUiEvent("pin-unselected");
                   }
                }
                else {
                    return false;
                }
                return true;
            };
            //---------------------------------------
            AnnotationDecorator._markers.push(marker);
            const vp = IModelApp.viewManager.selectedView;
            if (vp === undefined) return;
            const nextId = vp.iModel.transientIds.getNext();
            this.objectIdMap.set(nextId, `defectMarker#${mlPoints[i].defectId}`);
          }
      }
    }
  }
}

//global statics //Hack!! Bad scope encapsulation
function clearClassElementsX(className: string) {
    let elements = document.getElementsByClassName(className);
    let idArray: string[] = [];
    if (elements) {
        for (let i = 0; i < elements.length; i++) {
            idArray.push(elements[i].id.toString());
        }
        for (let j = 0; j < idArray.length; j++) {
            clearElementX(idArray[j]);
        }
    }
}
function clearElementX(id : any) {
    var elem = document.getElementById(id);
    elem?.parentNode?.removeChild(elem);
}



